.whiteText {
    color: #fff;
}

.noPaddingCol {
    padding: 0px;
}

.header {
    padding: 20px;
}

.contentRow {
    height: calc(100vh - 65px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer {
    width: 90vw;
    height: 90vh;
    margin-left: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}