.colInner {
    margin-bottom: 50px;
    padding: 20px;
}

.thumbnailContainer {
    width: 400px;
    height: 400px;
    box-shadow: 5px 5px 25px #222;
    background-color: #fff;
}

@media screen and (max-width: 1700px) {
    .thumbnailContainer {
        width: 300px;
        height: 300px;
        box-shadow: 5px 5px 25px #222;
        background-color: #fff;
    }
}

@media screen and (max-width: 1300px) {
    .thumbnailContainer {
        width: 200px;
        height: 200px;
        box-shadow: 5px 5px 25px #222;
        background-color: #fff;
    }
}

@media screen and (max-width: 992px) {
    .thumbnailContainer {
        width: 400px;
        height: 400px;
        box-shadow: 5px 5px 25px #222;
        background-color: #fff;
    }
}

.imageContainer {
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.captionContainer {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageThumbnail {
    max-width: 90%;
    max-height: 90%;
}

.text {
    color: #777;
}